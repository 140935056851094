
















































































import { defineComponent } from "@vue/composition-api";
import { Button, CellGroup, Dialog, Field, Popup, Toast, Image, ImagePreview, Form } from "vant";
import axios from "@/helpers/axios";

interface Block {
  name: string;
  data: BlockItem[];
}

interface BlockItem {
  label: string;
  value: string;
  type: string;
}

export default defineComponent({
  props: ["id"],
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    [Image.name]: Image,
    [Form.name]: Form
  },
  data() {
    return {
      canConfirm: false,
      status: -1,
      statusName: "",
      confirmName: "",
      applyUser: "",
      commitUser: "",
      type: 0,
      typeName: "",
      businessName: "",
      createTime: "",
      detail: {
        mark: "",
        list: [] as Block[]
      },
      refuseReason: "",
      level: 0,
      reviewLogList: [],

      videoUrl: "",
      showVideo: false,

      showDeclineDialog: false,
      declineReason: ""
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = await axios
        .post("/api/manage/review/getDetail", { auditReviewId: this.id })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.canConfirm = data.is_hava_current_comfirm_perssion;
      this.statusName = data.status_name;
      this.status = data.status;
      this.confirmName = data.confirm_name;
      this.applyUser = data.apply_user_name + " " + data.apply_user_mobile;
      this.commitUser = data.commit_user_name + " " + data.commit_user_mobile;
      this.businessName = data.business_name;
      this.createTime = data.create_time;
      this.type = data.type;
      this.typeName = data.type_name;
      this.refuseReason = data.refuse_reason;
      this.level = data.need_level;
      this.reviewLogList = data.review_logs;
      this.detail = data.detail;
    },
    async submit(data: Record<string, string>, action: string) {
      const res = await axios
        .post(action, data)
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Toast.success("保存成功");
    },
    onClickBlockData(item: BlockItem) {
      if (item.type == "video" && item.value) {
        this.videoUrl = item.value;
        this.showVideo = true;
      }
    },
    imagePreview(url: string) {
      ImagePreview([url]);
    },
    setShowDeclineDialog() {
      this.showDeclineDialog = true;
    },
    decline() {
      if (!this.declineReason) {
        Toast.fail("请填写拒绝原因");
        return;
      }
      axios.post("/api/manage/review/reject", { auditReviewId: this.id, reason: this.declineReason }).then(() => {
        Toast.success("操作成功");
        this.getData();
      });
    },
    async confirm() {
      const res = await Dialog.confirm({
        message: "您确定吗?"
      })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      axios.post("/api/manage/review/confirm", { auditReviewId: this.id, step: this.level }).then(() => {
        Toast.success("操作成功");
        this.getData();
      });
    },
    toUrl(url: string) {
      if (url.startsWith("/web/manage")) {
        this.$toMiniAppWeb(url);
      } else {
        this.$router.push(url);
      }
    }
  }
});
